import { useState, useContext } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import style from './loginSignUp.module.css'
import signIn from '../../../lib/signin'
import { FirebaseAuthContext } from '../../../context/firebaseAuthContext'
const CustomButton = dynamic(() => import('../../buttons/customButton'))
const AlertModal = dynamic(() => import('../../modals/modalAlert'))
const LoadingComponent = dynamic(() => import('../../shared/loadingComponent'))


export default function LoginFormComponent() {

    const authApi = useContext(FirebaseAuthContext)
    const { user, loading } = authApi
    const router = useRouter()

    const [show, setShow] = useState(false)

    const handleClose = () => {
        setShow(false);
        window.location.reload()
    }
    const [message, setMessage] = useState('')

    const [disabled, setDisabled] = useState(false)
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .max(50, 'Must be 50 characters or less')
                .required('Required'),
            email: Yup.string().email('Invalid email address').required('Required'),
        }),
        onSubmit: async (values) => {

            setDisabled(true)

            let email = await values.email.trim()
            let password = await values.password.trim()
            const resp = await signIn(email, password)
            if (resp.result != null) {
                await router.push('/')

            } if (resp.error !== null) {
                await setMessage('Wrong email/password!')
                await setShow(true)
            }
        },
    });
    if (loading) {
        return (<LoadingComponent />)
    }
    if (user === null) {
        return (<>
            <article className='sectionOrArticleTB'>
                <Container>
                    <Row className="justify-content-center" >
                        <Col xs={12} md={8} lg={6} className='text-center'>

                            <AlertModal
                                show={show}
                                alert={message}
                                handleClose={handleClose}
                            />

                            <form onSubmit={formik.handleSubmit} className={style.formBox}>
                                <h1 className='caregoriesTitle'>Login</h1><br />

                                <label htmlFor="email">Email Address</label><br />
                                <input
                                    id="email"
                                    className={style.formInputs}
                                    name="email"
                                    type="email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <div className={style.errorColor}>{formik.errors.email}</div>
                                ) : null}
                                <br /> <br />

                                <label htmlFor="firstName">Password</label><br />
                                <input
                                    id="lastName"
                                    className={style.formInputs}
                                    name="password"
                                    type="password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                />
                                {formik.touched.password && formik.errors.password ? (
                                    <div className={style.errorColor}>{formik.errors.password}</div>
                                ) : null}
                                <br />  <br />
                                <CustomButton type="submit" maroonBtn disabled={disabled}>Log in</CustomButton>
                                <p className='mt-1 mt-sm-2 mt-md-3 mt-lg-4'>Do not have account?</p>

                                <Link href="/signUp" passHref>Create account</Link>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </article>
        </>)
    } else {
        return null
    }
}